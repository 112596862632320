<template>
  <Dialog
    :header="$t('cases.auditCase') + ' #' + data.id"
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '60vw' }"
  >
    <div class="grid grid-nogutter">
      <div class="col">
        <DateAndCompanyInfo :data="data"></DateAndCompanyInfo>
      </div>
      <div
        class="col-1 flex justify-content-center flex-column align-items-center border-circle mt-3 w-8rem h-8rem mb-3"
        :style="
          rejected ? 'background-color: #FE5722' : 'background-color: #0094da'
        "
      >
        <span
          class="m-0 font-bold text-white-alpha-90"
          :class="rejected ? 'text-lg' : 'text-5xl'"
        >
          {{
            rejected
              ? $t("cases.rejected")
              : data.schemeInstance.evaluationPoints
          }}
        </span>
        <p v-if="!rejected" class="font-light text-white-alpha-90">
          {{ $t("cases.outOf") }} {{ data.schemeInstance.totalPoints }}
        </p>
      </div>
    </div>

    <div class="grid flex-column grid-nogutter">
      <div class="col mb-3">
        <span class="m-0 text-xl font-bold">{{
          data.schemeInstance.title
        }}</span>
      </div>
      <div class="col-12">
        <span class="text-base lg:text-lg font-bold">
          {{ data.schemeInstance.instructionsForAssessmentLabel }}
        </span>
      </div>
      <div
        class="col-12"
        v-for="(item, index) in data.schemeInstance.instructionsForAssessment"
        :key="index"
      >
        <h6 class="text-base m-0">
          {{ item }}
        </h6>
      </div>
      <div class="col-12">
        <span class="text-base lg:text-lg font-bold">
          {{ data.schemeInstance.scoreRequirementLabel }}
        </span>
      </div>
      <div class="col-12">
        <h6 class="text-base m-0">
          {{ data.schemeInstance.scoreRequirement }}
        </h6>
      </div>
      <div class="col-12">
        <span class="text-base lg:text-lg font-bold">
          {{ data.schemeInstance.assessmentCriteriaLabel }}
        </span>
      </div>
      <div class="col-12">
        <h6 class="text-base m-0">
          {{ data.schemeInstance.assessmentCriteria }}
        </h6>
      </div>
      <div class="col-12">
        <span class="text-base lg:text-lg font-bold">
          {{ data.schemeInstance.scoreSheetLabel }}
        </span>
      </div>
      <div class="col mt-4">
        <p>{{ $t("cases.reviewAndApprove") }}</p>
      </div>

      <div
        class="col"
        v-for="(scoreItem, indexScoreItem) in data.schemeInstance.scoreItems"
        :key="indexScoreItem"
      >
        <div class="grid grid-nogutter bg-gray-600">
          <div class="col-10">
            <span class="m-2 text-white-alpha-90 text-xl">{{
              scoreItem.title
            }}</span>
          </div>
          <div class="col-2 align-self-center text-white-alpha-90">
            <p>{{ scoreItem.scoreItemPoints }} /{{ scoreItem.points }}</p>
          </div>
        </div>
        <div
          v-for="(item, itemIndex) in scoreItem.items"
          :key="itemIndex"
          class="pt-3"
        >
          <div
            class="grid grid-nogutter pl-0 lg:pl-4"
            style="background-color: #bdbdbd"
          >
            <div class="col-10 align-self-center">
              <span class="m-2 text-sm lg:text-lg">{{ item.title }}</span>
            </div>
            <div class="col-2 align-self-center">
              <p>{{ item.itemPoints }} /{{ item.points }}</p>
            </div>
          </div>

          <div
            v-for="(assessment, assessmentIndex) in item.assessments"
            :key="assessmentIndex"
          >
            <AssessmentsView
              v-if="
                checkRole(['auditor']) &&
                data.status != CaseStatuses.TO_BE_AMENDED.name
              "
              :assessment="assessment"
              :assessmentIndex="assessmentIndex"
              :indexScoreItem="indexScoreItem"
              :itemIndex="itemIndex"
              :submitted="submitted"
              :rejected="rejected"
              @calcPoints="calculatePoints"
            ></AssessmentsView>
            <div
              v-else
              class="grid grid-nogutter pl-0 lg:pl-5 my-3"
              :class="assessmentIndex % 2 != 0 ? 'grayBackground' : ''"
            >
              <div class="col-10 lg:col-5 align-self-center">
                <h6 class="m-0 mb-2 pr-7">{{ assessment.title }}</h6>
              </div>
              <div class="col-9 lg:col-4 align-self-center m-0 field p-fluid">
                <span>{{
                  assessment.criteria.find(
                    (el) => el.id == assessment.selectedCriteriaSchemeId
                  ).title
                }}</span>
              </div>

              <div class="col-2 col-offset-1 align-self-center">
                <p>
                  <template
                    v-if="
                      !assessment.pointsFromSelectedCriteria ||
                      assessment.pointsFromSelectedCriteria < 0
                    "
                  >
                    0
                  </template>
                  <template v-else>{{
                    assessment.pointsFromSelectedCriteria
                  }}</template>
                  /{{ assessment.points }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-nogutter pl-0 lg:pl-5 my-3">
      <div class="col-10 lg:col-5 align-self-center">
        <h6 class="m-0 mb-2">{{ $t("cases.comments") }}:</h6>
      </div>
      <div class="col-12 lg:col-7 align-self-center m-0 field p-fluid">
        <Textarea
          v-if="checkRole(['auditor'])"
          v-model="data.schemeInstance.comments"
          :autoResize="true"
          rows="5"
        />
        <span v-else>
          <span>{{ data.schemeInstance.comments }}</span>
        </span>
      </div>
    </div>
    <DataTable
      :value="auditCaseFiles"
      :paginator="true"
      :rows="10"
      :loading="tableLoading"
      dataKey="id"
      showGridlines
      class="p-datatable-files my-5 border-2 border-black-alpha-20"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      :currentPageReportTemplate="$tableString"
      removableSort
      breakpoint="1366px"
    >
      <template #empty> {{ $t("files.noFilesFound") }} </template>
      <Column field="id" :header="$t('files.fileId')" :sortable="true">
        <template #body="{ data }">
          {{ data.id }}
        </template>
      </Column>
      <Column field="name" :header="$t('files.fileName')" :sortable="true">
        <template #body="{ data }">
          <span style="color: #0d89ec">
            {{ data.name }}
          </span>
        </template>
      </Column>
      <Column field="Actions" :header="$t('global.actions')">
        <template #body="{ data }">
          <div class="text-center">
            <Button
              @click="download(data)"
              class="p-button-text"
              icon="mdi mdi-36px mdi-download"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <h5>{{ $t("cases.uploadFile") }} ({{ $t("global.fileSmaller") }})</h5>
    <div>
      <FileUpload
        name="file"
        :multiple="true"
        :maxFileSize="$fileSize"
        :showCancelButton="false"
        :customUpload="true"
        v-if="checkRole(['auditor'])"
        @uploader="uploadFile"
        :invalidFileSizeMessage="`{0}: ${$t('global.invalidFileSize')} {1}.`"
        :invalidFileTypeMessage="`{0}: ${$t('global.invalidFileType')} {1}.`"
        @select="selectedFiles++"
        @remove="selectedFiles--"
        :chooseLabel="$t('global.choose')"
        :uploadLabel="$t('global.upload')"
      >
        <template #empty>
          <p>{{ $t("global.dragAndDrop") }}</p>
        </template>
      </FileUpload>
    </div>
    <template #footer>
      <div v-if="checkRole(['client'])">
        <Button
          :label="$t('global.accept')"
          class="p-button-success"
          autofocus
          iconPos="right"
          :loading="loadingButton"
          @click="
            updateStatus(CaseStatuses.TO_BE_REVIEWED_BY_REVIEWER.name, true)
          "
        />
        <Button
          :label="$t('global.reject')"
          class="p-button-danger"
          autofocus
          iconPos="right"
          @click="
            updateStatus(CaseStatuses.AUDIT_REJECTED_BY_CLIENT.name, false)
          "
        />
      </div>
      <div v-else class="grid grid-nogutter">
        <div
          class="col-12 lg:col-4 mb-2 lg:mb-0 grid grid-nogutter h-3rem text-white-alpha-90"
          :class="
            checkRole(['auditor']) ||
            checkRole(['manager-for-certification-decision'])
              ? 'col-6'
              : 'col-12'
          "
          :style="
            rejected ? 'background-color: #FE5722' : 'background-color: #0094da'
          "
        >
          <div
            class="col-6 lg:col m-0 flex align-items-center justify-content-center font-semibold text-base lg:text-xl"
          >
            {{ $t("cases.totalScore") }}
          </div>
          <div
            class="col-6 lg:col align-self-center flex align-items-center justify-content-center text-base lg:text-xl"
          >
            <template v-if="rejected">
              {{ $t("cases.rejected") }}
            </template>
            <template v-else>
              {{
                data.schemeInstance.evaluationPoints
                  ? data.schemeInstance.evaluationPoints
                  : 0
              }}
              /{{ data.schemeInstance.totalPoints }}
            </template>
          </div>
        </div>

        <!-- <div
          class="col-6 lg:col-2 align-self-center"
          v-if="checkRole(['auditor'])"
        >
          <Button class="p-button-primary" autofocus>
            <span class="mdi mdi-clipboard-text-outline mr-1"></span>
            <span>{{ $t("cases.addFiles") }}</span>
          </Button>
        </div> -->

        <div
          class="col-6 lg:col-2 align-self-center"
          v-if="
            checkRole(['audit-reviewer', 'manager-for-certification-decision'])
          "
        >
          <Button class="p-button-primary" autofocus @click="printAudit">
            <span class="mdi mdi-clipboard-text-outline mr-1"></span>
            <span>{{ $t("cases.print") }}</span>
          </Button>
        </div>

        <div
          v-if="checkRole(['auditor'])"
          class="col col-offset-0 md:col-offset-0 lg:col-offset-1 align-self-center flex justify-content-between lg:justify-content-end md:justify-content-evenly"
        >
          <Button
            :label="$t('cases.finalizeAudit')"
            class="p-button-success"
            :class="screenWidth < 1091 ? 'p-button-sm' : 'p-button'"
            @click="submit(true)"
            autofocus
            iconPos="right"
            :loading="loadingButton"
          />
          <Button
            :label="$t('global.save')"
            @click="submit(false)"
            class="p-button-warning"
            :class="screenWidth < 1091 ? 'p-button-sm' : 'p-button'"
            iconPos="right"
            :loading="loadingSave"
          />
          <Button
            :label="$t('global.close')"
            @click="close(false)"
            class="p-button-danger"
            :class="screenWidth < 1091 ? 'p-button-sm' : 'p-button'"
          />
        </div>

        <div
          v-else-if="
            checkRole(['head-technical-reviewer', 'technical-reviewer'])
          "
          class="col col-offset-0 md:col-offset-0 lg:col-offset-1 align-self-center flex justify-content-between lg:justify-content-end md:justify-content-evenly"
        >
          <Button
            :label="$t('global.accept')"
            class="p-button-success"
            @click="updateStatus(CaseStatuses.TO_BE_CERTIFIED.name, true)"
            autofocus
            iconPos="right"
            :loading="loadingAccept"
          />
          <Button
            :label="$t('global.reject')"
            @click="updateStatus(CaseStatuses.TO_BE_AMENDED.name, false)"
            class="p-button-danger"
            :loading="loadingReject"
          />
        </div>
        <div
          v-else-if="
            checkRole(['manager-for-certification-decision']) &&
            data.status != CaseStatuses.CERTIFIED.name
          "
          class="col col-offset-0 justify-content-end"
        >
          <Button
            :label="$t('cases.issue')"
            class="p-button-success"
            @click="issueCertification(data.id, data.version)"
            iconPos="right"
            :loading="issueButtonLoading"
          >
          </Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import DateAndCompanyInfo from "./DateAndCompanyInfo.vue";
import AssessmentsView from "./AssessmentsView.vue";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters, mapState } from "vuex";
export default {
  name: "View finished audit case",
  props: ["display", "dataValue"],
  emits: ["closeAuditDialog"],
  setup: () => ({ v$: useVuelidate() }),

  components: {
    DateAndCompanyInfo,
    AssessmentsView,
  },
  data() {
    return {
      viewDialog: false,
      loadingButton: false,
      loadingSave: false,
      submitted: false,
      rejected: false,
      rejectedScoreItemIndex: null,
      rejectedItemIndex: null,
      rejectedAssessmentIndex: null,
      data: {},
      issueButtonLoading: false,
      tableLoading: false,
      buttonElement: "",
      selectedFiles: 0,
      screenWidth: window.innerWidth,
      loadingAccept: false,
      loadingReject: false,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  watch: {
    async display(val) {
      this.viewDialog = val;
      if (val) {
        this.data = JSON.parse(JSON.stringify(this.dataValue));
        this.calculatePoints();

        this.tableLoading = true;
        await this.$store.dispatch(
          "getSchemeInstanceFile",
          this.data.schemeInstance.id
        );
        this.tableLoading = false;
        if (
          navigator.geolocation &&
          this.data.schemeInstance.auditStartLocation == null
        ) {
          navigator.geolocation.getCurrentPosition(async (location) => {
            this.data.schemeInstance.auditStartLocation = {};
            this.data.schemeInstance.auditStartLocation.latitude =
              location.coords.latitude;
            this.data.schemeInstance.auditStartLocation.longitude =
              location.coords.longitude;
            await this.$store.dispatch(
              "updateSchemeInstance",
              this.data.schemeInstance
            );
          });
        }
        console.log(this.data);
        console.log(this.auditCaseFiles);
      }
      console.log(this.data.schemeInstance);
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        if (
          this.checkRole(["client"]) &&
          this.data.status == this.CaseStatuses.TO_BE_ACCEPTED_BY_CLIENT.name
        ) {
          this.$action("audit-completed-view", {
            reviewId: this.data.schemeInstance.id,
          });
        }

        this.$nextTick(() => {
          this.buttonElement = document.querySelector(
            ".p-fileupload-buttonbar button"
          );
          if (this.buttonElement) {
            this.buttonElement.style.display = "none";
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters(["checkRole", "auditCaseFiles"]),
    ...mapState(["CaseStatuses"]),
    selectedValue() {
      return (assessment) => {
        if (assessment.selectedCriteriaSchemeId == null) {
          return "";
        } else {
          return assessment.criteria.find(
            (el) => el.id == assessment.selectedCriteriaSchemeId
          );
        }
      };
    },
  },
  methods: {
    printAudit() {
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      var divContents = document.querySelector(".p-dialog-content").innerHTML;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      const caseTitle = document.querySelector(".p-dialog-title").innerText;
      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body style="zoom:68%;">
  <div>
  <h1>${caseTitle}</h1>
  </div>
    ${divContents}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    async calculatePoints(
      indexScoreItem,
      itemIndex,
      assessmentIndex,
      assessment = false,
      event = false
    ) {
      this.rejected = false;
      this.rejectedScoreItemIndex = null;
      this.rejectedItemIndex = null;
      this.assessmentIndex = null;
      if (assessment && event) {
        assessment.pointsFromSelectedCriteria =
          (assessment.points * event.value.scoreRatio) / 100;

        assessment.selectedCriteriaSchemeId = event.value.id;
      }

      this.data.schemeInstance.evaluationPoints = 0;
      this.data.schemeInstance.scoreItems.forEach((elScoreItem) => {
        let scoreItemPoints = 0;
        elScoreItem.scoreItemPoints = 0;
        elScoreItem.items.forEach((elItem) => {
          let itemPoints = 0;
          elItem.itemPoints = 0;
          elItem.assessments.forEach((elAssessment) => {
            if (!isNaN(elAssessment.pointsFromSelectedCriteria)) {
              if (elAssessment.pointsFromSelectedCriteria < 0) {
                this.rejected = true;
                this.rejectedScoreItemIndex = indexScoreItem;
                this.rejectedItemIndex = itemIndex;
                this.assessmentIndex = assessmentIndex;
                elAssessment.pointsFromSelectedCriteria = -1;
              } else {
                itemPoints += elAssessment.pointsFromSelectedCriteria;
              }
            }
          });
          scoreItemPoints += itemPoints;
          elItem.itemPoints = itemPoints;
        });
        elScoreItem.scoreItemPoints = scoreItemPoints;
        this.data.schemeInstance.evaluationPoints += scoreItemPoints;
      });

      const allFieldsSelected = await this.v$.$validate();
      if (
        this.data.schemeInstance.evaluationPoints <
          this.data.schemeInstance.passPoints &&
        allFieldsSelected
      ) {
        this.rejected = true;
      }
    },
    close(success = false) {
      console.log("call close");
      this.$emit("closeAuditDialog", success);
      this.data = {};
      this.submitted = false;
      this.rejected = false;
      this.rejectedScoreItemIndex = null;
      this.rejectedItemIndex = null;
      this.assessmentIndex = null;
      this.selectedFiles = 0;
      this.$store.commit("deleteAuditCaseFiles");
    },
    async uploadFile(event) {
      // await Promise.all(
      //   event.files.map(async (el) => {
      //     await this.$store.dispatch("uploadFilesSchemeInstance", {
      //       id: this.data.schemeInstance.id,
      //       file: el,
      //       userId: this.data.application.id,
      //     });
      //   })
      // );

      for (let i = 0; i < event.files.length; i++) {
        await this.$store.dispatch("uploadFilesSchemeInstance", {
          id: this.data.schemeInstance.id,
          file: event.files[i],
          userId: this.data.application.userId,
        });
      }

      if (this.loadingButton) {
        this.$notification(
          this.$t("cases.updateCase"),
          this.$t("cases.caseWasFinalized")
        );
        this.$action(
          "audit-end",
          {
            reviewId: this.data.schemeInstance.id,
            caseId: this.data.id,
            applicationId: this.data.application.id,
          },
          "both"
        );
        this.loadingButton = false;
      } else if (this.loadingSave) {
        this.$notification(
          this.$t("cases.updateCase"),
          this.$t("cases.caseWasSaved")
        );
        this.$action("audit-start", {
          reviewId: this.data.schemeInstance.id,
        });
        this.loadingSave = false;
      }
      this.close(true);
    },
    getLocation() {
      return new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(res, rej);
      });
    },
    async submit(final) {
      if (final) {
        const result = await this.v$.$validate();

        this.submitted = true;
        if (result) {
          console.log(this.data);
          this.loadingButton = true;

          if (this.rejected) {
            this.data.schemeInstance.status = "rejected";
          } else if (
            this.data.schemeInstance.evaluationPoints >=
            this.data.schemeInstance.passPoints
          ) {
            this.data.schemeInstance.status = "passed";
          } else if (
            this.data.schemeInstance.evaluationPoints <
            this.data.schemeInstance.passPoints
          ) {
            this.data.schemeInstance.status = "rejected";
          }

          if (
            navigator.geolocation &&
            this.data.schemeInstance.auditEndLocation == null
          ) {
            let position = await this.getLocation();
            this.data.schemeInstance.auditEndLocation = {};
            this.data.schemeInstance.auditEndLocation.latitude =
              position.coords.latitude;
            this.data.schemeInstance.auditEndLocation.longitude =
              position.coords.longitude;
            await this.$store.dispatch(
              "updateSchemeInstance",
              this.data.schemeInstance
            );
          } else {
            await this.$store.dispatch(
              "updateSchemeInstance",
              this.data.schemeInstance
            );
          }

          await this.$store.dispatch("updateCaseStatus", {
            id: this.data.id,
            version: this.data.version,
            status:
              this.data.status == this.CaseStatuses.TO_BE_AMENDED.name
                ? this.CaseStatuses.TO_BE_REVIEWED_BY_REVIEWER.name
                : this.CaseStatuses.TO_BE_ACCEPTED_BY_CLIENT.name,
          });

          if (this.selectedFiles > 0) {
            this.buttonElement.click();
          } else {
            this.$notification(
              this.$t("cases.updateCase"),
              this.$t("cases.caseWasFinalized")
            );
            this.loadingButton = false;
            if (this.data.status == this.CaseStatuses.TO_BE_AMENDED.name) {
              this.$action(
                "audit-amend",
                {
                  reviewId: this.data.schemeInstance.id,
                  caseId: this.data.id,
                },
                "both"
              );
            } else {
              this.$action(
                "audit-end",
                {
                  reviewId: this.data.schemeInstance.id,
                  caseId: this.data.id,
                  applicationId: this.data.application.id,
                },
                "both"
              );
            }
            this.close(true);
          }
        }
      } else {
        this.loadingSave = true;
        await this.$store.dispatch(
          "updateSchemeInstance",
          this.data.schemeInstance
        );
        if (this.selectedFiles > 0) {
          this.buttonElement.click();
        } else {
          this.$notification(
            this.$t("cases.updateCase"),
            this.$t("cases.caseWasSaved")
          );
          this.loadingSave = false;
          this.$action("audit-start", {
            reviewId: this.data.schemeInstance.id,
          });
          this.close(true);
        }
        // this.$notification(
        //   this.$t("cases.updateCase"),
        //   this.$t("cases.caseWasSaved")
        // );
        // this.loadingSave = false;
      }
    },
    async updateStatus(status, accepted) {
      this.loadingButton = true;
      if (accepted) {
        this.loadingAccept = true;
      } else {
        this.loadingReject = true;
      }
      try {
        await this.$store
          .dispatch("updateCaseStatus", {
            id: this.data.id,
            version: this.data.version,
            status,
          })
          .then(() => {
            if (status == this.CaseStatuses.TO_BE_CERTIFIED.name) {
              this.$notification(
                this.$t("cases.updateStatus"),
                this.$t("cases.acceptCase")
              );

              this.$action(
                "audit-completed-approve",
                {
                  reviewId: this.data.schemeInstance.id,
                  caseId: this.data.id,
                },
                "both"
              );
            } else if (status == this.CaseStatuses.TO_BE_AMENDED.name) {
              this.$notification(
                this.$t("cases.updateStatus"),
                this.$t("cases.rejectCase")
              );
              this.$action(
                "audit-completed-reject",
                {
                  reviewId: this.data.schemeInstance.id,
                  caseId: this.data.id,
                },
                "both"
              );
            }

            if (this.checkRole(["client"])) {
              if (status == this.CaseStatuses.TO_BE_REVIEWED_BY_REVIEWER.name) {
                this.$action(
                  "audit-completed-approve",
                  {
                    reviewId: this.data.schemeInstance.id,
                    caseId: this.data.id,
                  },
                  "both"
                );
              } else if (
                status == this.CaseStatuses.AUDIT_REJECTED_BY_CLIENT.name
              ) {
                this.$action(
                  "audit-completed-reject",
                  {
                    reviewId: this.data.schemeInstance.id,
                    caseId: this.data.id,
                  },
                  "both"
                );
              }
            }
          });

        this.close(true);
        this.loadingButton = false;
        this.loadingAccept = false;
        this.loadingReject = false;
      } catch {
        this.loadingButton = false;
        this.loadingAccept = false;
        this.loadingReject = false;
      }
    },
    async issueCertification(id, version) {
      this.issueButtonLoading = true;
      await this.$store
        .dispatch("updateCaseStatus", {
          id,
          version,
          status: this.CaseStatuses.CERTIFIED.name,
        })
        .then((data) => {
          this.$action(
            "certificate-issue",
            {
              certificationId: data.certifiedId,
              caseId: id,
              applicationId: data.application.id,
            },
            "certificate"
          );
        });
      this.issueButtonLoading = false;
      this.$notification(
        this.$t("certifications.issueCertifitcation"),
        this.$t("certifications.certificationIssued")
      );
      this.close(true);
    },
    download(fileData) {
      this.$store.dispatch("downloadSchemeInstanceFile", fileData);
    },
  },
};
</script>

<style lang="scss" scoped>
.grayBackground {
  background-color: #f9f9f9;
}

// @media all{
//    .p-dialog-title{
//        display:none;
//    }
// }

// @media print{
//    .p-dialog-title{
//        display:block;
//    }
//    .logo{
//        width: 400px;
//        display: list-item;
//        list-style-position: inside;
//    }
// }
</style>
