<template>
  <Dialog
    :header="$t('cases.auditCase') + ' #' + data.id"
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '70vw' }"
  >
    <div class="grid flex-column grid-nogutter">
      <div class="col">
        <DateAndCompanyInfo :data="data"></DateAndCompanyInfo>
      </div>
      <Divider />
      <div v-if="!showAuditSections">
        <div class="col">
          <h4>{{ $t("cases.assignedAuditors") }}</h4>
        </div>
        <div v-for="(auditor, index) in data.auditors" :key="index" class="col">
          <div class="grid grid-nogutter">
            <div class="col-3">
              <p class="shadow-2">
                {{ auditor.firstName }} {{ auditor.lastName }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <AuditSectionsTable
          :caseData="data"
          :loadingValue="false"
        ></AuditSectionsTable>
      </div>
    </div>
    <template
      #footer
      v-if="
        !checkRole([
          'certification-manager',
          'quality-assurance-manager',
          'product-manager',
        ])
      "
    >
      <Button
        :label="$t('global.accept')"
        class="p-button-success"
        autofocus
        iconPos="right"
        :loading="loadingButton"
        @click="acceptStatus"
      />
      <Button
        :label="$t('global.reject')"
        class="p-button-danger"
        :loading="loadingReject"
        iconPos="right"
        @click="rejectStatus"
      />
    </template>
  </Dialog>
</template>

<script>
import DateAndCompanyInfo from "./DateAndCompanyInfo.vue";
import AuditSectionsTable from "../Calendar/AuditSectionsTable.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "View case",
  components: {
    DateAndCompanyInfo,
    AuditSectionsTable,
  },
  props: ["dataValue", "display", "showAuditSections"],
  emits: ["closeCaseViewDialog"],
  data() {
    return {
      viewDialog: false,
      loadingButton: false,
      loadingReject: false
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      console.log(this.data);
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      }
    },
  },
  computed: {
    data() {
      return { ...this.dataValue };
    },
    ...mapGetters(["checkRole"]),
    ...mapState(["CaseStatuses"]),
  },
  methods: {
    close(success = false) {
      this.$emit("closeCaseViewDialog", success);
    },
    async acceptStatus() {
      this.loadingButton = true;
      if (this.showAuditSections) {
        // updateObject.status = "to be audited";
        await this.$store.dispatch("updateAuditPlanStatus", {
          id: this.data.auditPlan.id,
          status: "accepted",
          version: this.data.auditPlan.version,
        }).then(() => {
          this.$action(
            "schedule-accept",
            { sceduleId: this.data.auditPlan.id, caseId: this.data.id, applicationId: this.data.application.id },
            "both"
          );
        })
      } else {
        await this.$store.dispatch("updateCaseStatus", {
          id: this.data.id,
          version: this.data.version,
          status: this.CaseStatuses.EVENT_ACCEPTED_BY_CLIENT.name,
        }).then(() => {
          this.$action("case-accept", { caseId: this.data.id }, "calendar");
        })
      }
      this.loadingButton = false;
      this.close(true);
    },
    async rejectStatus() {
      this.loadingReject = true;
      if (this.showAuditSections) {
        // updateObject.status = "schedule rejected by client";
        await this.$store.dispatch("updateAuditPlanStatus", {
          id: this.data.auditPlan.id,
          status: "rejected",
          version: this.data.auditPlan.version,
        }).then(() => {
          this.$action(
            "schedule-reject",
            { sceduleId: this.data.auditPlan.id, caseId: this.data.id },
            "both"
          );
        })
      } else {
        await this.$store.dispatch("updateCaseStatus", {
          id: this.data.id,
          version: this.data.version,
          status: this.CaseStatuses.EVENT_REJECTED_BY_CLIENT.name,
        }).then(() => {
          this.$action("case-reject", { caseId: this.data.id }, "calendar");
        })
      }
      this.loadingReject = false;
      this.close(true);
    },
  },
};
</script>
