<template>
  <div>
    <Dialog
      :header="$t('cases.viewInvoice')"
      v-model:visible="viewDialog"
      :draggable="false"
      :modal="true"
      :style="{ width: '60vw' }"
    >
      <div v-if="loading" class="flex justify-content-center">
        <ProgressSpinner />
      </div>
      <div v-else>
        <!-- <iframe :src="invoiceView" class="w-full" style="height: 50rem" frameborder="0"></iframe> -->
        <RenderFile></RenderFile>
      </div>
      <template #footer>
        <Button
          id="acceptButton"
          :label="$t('global.close')"
          :class="'p-button-danger'"
          @click="close()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RenderFile from "../RenderFile.vue";
export default {
  name: "View invoice",
  components: {
    RenderFile,
  },
  props: ["display", "case"],
  emits: ["closeViewInvoice"],
  data() {
    return {
      viewDialog: false,
      loading: false,
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        this.loading = true;
        await this.$store
          .dispatch("downloadFile", {
            data: { id: this.case.filesInvoice[0] },
            download: false,
          })
          .then(() => {
            if (this.checkRole(["client"])) {
              this.$action("invoice-view", { invoiceId: this.case.invoiceId });
            }
          });
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["checkRole"]),
  },
  methods: {
    close(val) {
      this.$emit("closeViewInvoice", val);
      // window.URL.revokeObjectURL(this.invoiceView);
    },
  },
};
</script>
