<template>
  <Dialog
    :header="$t('cases.auditCase') + ' #' + data.id"
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '50vw' }"
  >
    <div class="grid flex-column grid-nogutter">
      <div class="col">
        <DateAndCompanyInfo :data="data"></DateAndCompanyInfo>
      </div>
    </div>

    <h5>{{ $t("cases.invoiceId") }}</h5>
    <p>{{ $t("cases.enterInvoiceId") }}</p>
    <InputText
      type="text"
      v-model="v$.invoiceId.$model"
      :class="{ 'p-invalid': v$.invoiceId.$invalid && submitted }"
      class="p-fluid w-15rem lg:w-20rem"
      :placeholder="$t('cases.enterInvoice')"
    />
    <ul
      v-if="v$.invoiceId.$error && submitted"
      class="p-error text-xs col-10 pl-3 mt-0 pt-1"
    >
      <li v-for="(error, index) of v$.invoiceId.$errors" :key="index">
        {{ error.$message.replace("[value]", $t("cases.invoiceId")) }}
      </li>
    </ul>
    <h5 class="mt-8">
      {{ $t("invoices.uploadInvoiceFile") }} ({{ $t("global.pdfSmaller") }})
    </h5>
    <FileUpload
      name="file"
      :multiple="false"
      :maxFileSize="$fileSize"
      :showCancelButton="false"
      :customUpload="true"
      @uploader="uploadFile"
      :invalidFileSizeMessage="`{0}: ${$t('global.invalidFileSize')} {1}.`"
      :invalidFileTypeMessage="`{0}: ${$t('global.invalidFileType')} {1}.`"
      @select="checkFiles"
      @remove="checkFiles"
      :chooseLabel="$t('global.choose')"
      :uploadLabel="$t('global.upload')"
    >
      <template #empty>
        <div class="upload">
          <div
            class="flex flex-column align-items-center justify-content-center"
          >
            <div>
              <span
                alt="Upload application"
                class="mdi mdi-96px mdi-orange mdi-cloud-upload-outline"
              ></span>
            </div>
            <div>
              {{ $t("invoices.uploadInvoice") }}
            </div>
            <!-- <div class="col">
            <Button
              :label="$t('applications.uploadSignedPdf')"
              class="mr-2 mb-2"
            />
          </div> -->
          </div>
        </div>
      </template>
    </FileUpload>
    <template #footer>
      <Button
        :label="$t('global.save')"
        @click="submit()"
        class="p-button-success"
        :disabled="disableSubmit ? 'disabled' : false"
        iconPos="right"
        :loading="loading"
      />
      <Button
        :label="$t('global.close')"
        @click="close(false)"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import DateAndCompanyInfo from "./DateAndCompanyInfo.vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/utilities/i18n-validators";
export default {
  name: "Add invoice",
  props: ["display", "dataValue"],
  emits: ["closeAddInvoice"],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    DateAndCompanyInfo,
  },
  data() {
    return {
      viewDialog: false,
      loading: false,
      disableSubmit: true,
      buttonElement: "",
      invoiceId: "",
      submitted: false,
    };
  },
  validations() {
    return {
      invoiceId: {
        required,
      },
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        this.$nextTick(() => {
          this.buttonElement = document.querySelector(
            ".p-fileupload-buttonbar button"
          );
          if (this.buttonElement) {
            this.buttonElement.style.display = "none";
          }
        });
      }
    },
  },
  computed: {
    data() {
      return {
        ...this.dataValue,
      };
    },
  },
  methods: {
    checkFiles(event) {
      if (event.files.length > 0) {
        this.disableSubmit = false;
      } else {
        this.disableSubmit = true;
      }
    },
    async uploadFile(data) {
      console.log(data);
      await this.$store.dispatch("uploadFile", {
        id: this.data.id,
        type: "invoice",
        file: data.files[0],
      });
      this.$notification(
        this.$t("cases.addInvoice"),
        this.$t("cases.invoiceAdded")
      );
      this.loading = false;
      this.close(true);
    },
    async submit() {
      this.submitted = true;
      const result = await this.v$.$validate();
      if (result) {
        this.loading = true;
        this.data.invoiceId = this.invoiceId;
        this.data.applicationId = this.data.application.id;
        this.data.auditDays = this.$dayjs(
          this.data.auditDateEnd,
          "DD/MM/YYYY"
        ).diff(this.$dayjs(this.data.auditDate, "DD/MM/YYYY"), "d");
        if (this.data.auditDays == 0) {
          this.data.auditDays += 1;
        }
        let tempAuditors = [];
        this.data.auditors.forEach((el) => {
          tempAuditors.push(el.id);
        });
        this.data.auditors = tempAuditors;
        await this.$store.dispatch("updateCase", this.data).then((data) => {
          this.$action(
            "invoice-send",
            {
              invoiceId: data.invoiceId,
              caseId: data.id,
              applicationId: data.application.id,
            },
            "both"
          );
        });
        this.buttonElement.click();
      }
    },
    close(success = false) {
      this.invoiceId = "";
      this.$emit("closeAddInvoice", success);
    },
  },
};
</script>
