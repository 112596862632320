<template>
  <div class="grid grid-nogutter pl-0 lg:pl-5 my-3">
    <div class="lg:col-offset-5 lg:col-7 col-12">
      <div class="p-2 pb-0" v-html="assessment.remarks"></div>
    </div>
    <div class="col-10 lg:col-5 align-self-center pt-3 pr-4">
      <h6 class="m-0 mb-2">{{ assessment.title }}</h6>
    </div>
    <div class="col-9 lg:col-4 align-self-center m-0 field p-fluid">
      <Dropdown
        :modelValue="selectedValue(assessment)"
        @change="
          calculatePoints(
            indexScoreItem,
            itemIndex,
            assessmentIndex,
            assessment,
            $event
          )
        "
        :class="{
          'p-invalid':
            v$.assessment.selectedCriteriaSchemeId.$invalid && submitted,
        }"
        :options="assessment.criteria"
        optionLabel="title"
        panelClass="overlay"
        appendTo="self"
      >
        <template #option="slotProps">
          <div class="criteria-item">
            <div>{{ slotProps.option.title }}</div>
          </div>
        </template>
      </Dropdown>
      <!-- <small
        v-if="
          (v$.assessment.selectedCriteriaSchemeId.$invalid && submitted) ||
          v$.assessment.selectedCriteriaSchemeId.$pending.$response
        "
        class="p-error"
        >{{
          v$.assessment.selectedCriteriaSchemeId.required.$message.replace(
            "[value]",
            "Assessment"
          )
        }}</small
      > -->
    </div>
    <div class="col-2 col-offset-1 align-self-center">
      <p>
        <template
          v-if="
            !assessment.pointsFromSelectedCriteria ||
            assessment.pointsFromSelectedCriteria < 0
          "
        >
          0
        </template>
        <template v-else>
          {{ assessment.pointsFromSelectedCriteria }}
        </template>
        /{{ assessment.points }}
      </p>
    </div>
    <ul
      v-if="v$.assessment.selectedCriteriaSchemeId.$error && submitted"
      class="p-error text-xs col-10 col-offset-0 md:col-4 md:col-offset-5 pl-3 mt-0"
    >
      <li
        v-for="(error, index) of v$.assessment.selectedCriteriaSchemeId.$errors"
        :key="index"
      >
        {{ error.$message.replace("[value]", $t("schemes.addAssessment")) }}
      </li>
    </ul>
  </div>
</template>

<script>
import { required } from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
export default {
  name: "Assessment view",
  props: [
    "indexScoreItem",
    "itemIndex",
    "assessmentIndex",
    "assessment",
    "submitted",
    "rejected",
  ],
  emits: ["calcPoints"],
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    selectedValue() {
      return (assessment) => {
        if (assessment.selectedCriteriaSchemeId == null) {
          return "";
        } else {
          return assessment.criteria.find(
            (el) => el.id == assessment.selectedCriteriaSchemeId
          );
        }
      };
    },
  },
  validations() {
    return {
      assessment: {
        selectedCriteriaSchemeId: {
          required,
        },
      },
    };
  },
  methods: {
    calculatePoints(
      indexScoreItem,
      itemIndex,
      assessmentIndex,
      assessment,
      $event
    ) {
      this.$emit(
        "calcPoints",
        indexScoreItem,
        itemIndex,
        assessmentIndex,
        assessment,
        $event
      );
    },
  },
};
</script>
