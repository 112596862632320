<template>
  <!-- <ViewFinishedAuditCase
    :display="viewAuditFinished"
    :dataValue="viewAuditFinishedData"
    @closeAuditViewDialog="closeFinishedViewAudit"
  ></ViewFinishedAuditCase> -->
  <ViewCertificate
    :display="openViewCertificate"
    :dataValue="selectedCertificate"
    @closeViewCertificate="closeViewCertificate"
  ></ViewCertificate>
  <ViewCase
    :display="openViewCase"
    :dataValue="viewCaseData"
    :showAuditSections="showAuditSections"
    @closeCaseViewDialog="closeViewCase"
  ></ViewCase>
  <AuditCase
    :display="viewAudit"
    :dataValue="viewAuditData"
    @closeAuditDialog="closeViewAudit"
  >
  </AuditCase>
  <AddInvoice
    :display="showAddInvoice"
    :dataValue="dataInvoice"
    @closeAddInvoice="closeAddInvoice"
  ></AddInvoice>
  <CaseHistory
    :display="viewCaseHistory"
    @closeCaseHistory="closeCaseHistory"
  ></CaseHistory>
  <ViewInvoice
    :display="viewInvoice"
    :case="invoiceCase"
    @closeViewInvoice="closeInvoice"
  >
  </ViewInvoice>
  <ConfirmDialog></ConfirmDialog>
  <BlockUI :blocked="blockedPage" :fullScreen="true"></BlockUI>
  <ProgressSpinner v-if="blockedPage" class="spinner" />
  <Card>
    <template #title v-if="loading || cases.length > 0">
      <div class="grid grid-nogutter">
        <div class="col-4">
          <h4>{{ pageTitle }}</h4>
        </div>
        <div class="col-8 md:col-2 md:col-offset-6 text-right">
          <Button
            v-if="!isMobile"
            :label="$t('global.export')"
            @click="exportCSV"
          ></Button>
        </div>
      </div>
    </template>
    <template
      #content
      v-if="
        loading ||
        ($route.name == 'invoices' && checkRole(['client'])
          ? invoiceCases
          : cases
        ).length > 0
      "
    >
      <DataTable
        :value="
          $route.name == 'invoices' && checkRole(['client'])
            ? invoiceCases
            : cases
        "
        :paginator="true"
        :rows="10"
        :filters="filters"
        dataKey="id"
        :loading="loading"
        ref="tableCases"
        showGridlines
        class="p-datatable-cases"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="$tableString"
        removableSort
        :breakpoint="`${breakPointValue}px`"
        sortField="auditDate"
        :sortOrder="-1"
        filterDisplay="menu"
        :globalFilterFields="[
          'id',
          'auditDate',
          'companyRepresentative',
          'company',
          'companyRepresentative',
          'invoiceId',
          'paymentDate',
          'comments',
        ]"
      >
        <template #header>
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('global.search')"
              />
            </span>
          </div>
        </template>
        <template #empty>{{ $t("cases.noCasesFound") }}</template>
        <Column
          field="id"
          :header="$t('cases.caseId')"
          :showFilterMatchModes="false"
        >
          <template #body="{ data }">
            <span class="text-right">{{ data.id }}</span>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="number"
              v-model.number="filterModel.value"
              class="p-column-filter"
              :placeholder="$t('global.searchById')"
            />
          </template>
          <template #filterapply="{ field, filterModel }">
            <Button
              :label="$t('global.apply')"
              class="p-button-primary"
              @click="filterApply(field, filterModel)"
            />
          </template>
          <template #filterclear="{ field }">
            <Button
              :label="$t('global.clear')"
              class="p-button-danger"
              @click="filterClear(field)"
            />
          </template>
        </Column>
        <Column
          field="tempAuditDate"
          :header="$t('cases.auditDate')"
          dataType="date"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.auditDate }}
          </template>
        </Column>
        <Column
          field="companyName"
          :header="$t('cases.company')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.companyName }}
          </template>
        </Column>
        <Column
          field="companyRepresentative"
          :header="$t('cases.companyRep')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.companyRepresentative }}
          </template>
        </Column>
        <Column
          :field="$route.name == 'invoices' ? 'invoiceStatus' : 'status'"
          :header="$t('global.status')"
          :sortable="true"
        >
          <template #body="{ data }">
            <span class="text-right">
              <template
                v-if="
                  checkRole(['client']) &&
                  data.status != CaseStatuses.EVENT_CREATED.name &&
                  data.status != CaseStatuses.SCHEDULE_CREATED.name &&
                  data.status != CaseStatuses.TO_BE_ACCEPTED_BY_CLIENT.name &&
                  data.status != CaseStatuses.CERTIFIED.name
                "
              >
                {{ $t("calendar.processing") }}
              </template>
              <template v-else>
                {{
                  $route.name == "invoices"
                    ? translatedStatus(data.invoiceStatus)
                    : translatedStatus(data.status)
                }}
              </template>
            </span>
          </template>
        </Column>
        <Column
          field="invoiceId"
          :header="$t('cases.invoiceId')"
          v-if="checkRole(['accounting'])"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.invoiceId ? data.invoiceId : $t("global.noValue") }}
          </template>
        </Column>
        <Column
          field="caseAuditor"
          :header="$t('cases.caseAuditor')"
          :sortable="true"
          :exportable="false"
          v-if="!checkRole(['accounting', 'client'])"
        >
          <template #body="{ data }">
            <div
              v-if="data.auditors && data.auditors.length > 0"
              class="flex flex-column"
            >
              <div v-for="(auditor, index) in data.auditors" :key="index">
                <span class="text-base">
                  {{ `${auditor.firstName} ${auditor.lastName}`
                  }}<template v-if="data.auditors.length != index + 1"
                    >,
                  </template>
                </span>
              </div>
            </div>
            <template v-else>
              {{ $t("cases.noAuditors") }}
            </template>
          </template>
        </Column>
        <Column
          field="approvalRejectionComparison"
          :header="$t('applications.approveRejectDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{
              data.approvalRejectionComparison > 0
                ? $dayjs(data.approvalRejectionComparison).format("DD/MM/YYYY")
                : $t("global.noDate")
            }}
          </template>
        </Column>
        <Column
          field="certification.schemeTitle"
          :header="$t('global.certificationType')"
          :sortable="true"
        >
        </Column>
        <Column
          field="tempInvoicePaidDate"
          :header="$t('cases.paymentDate')"
          :sortable="true"
          datatype="date"
          v-if="checkRole(['accounting'])"
        >
          <template #body="{ data }">
            {{
              data.invoicePaidDate ? data.invoicePaidDate : $t("global.noDate")
            }}
          </template>
        </Column>
        <Column field="comments" :header="$t('applications.comments')">
          <template #body="{ data, index }">
            <CommentsFields
              :dataValue="data"
              :indexValue="index"
              :type="'cases'"
            ></CommentsFields>
          </template>
        </Column>
        <Column
          field="reviewer"
          :header="$t('cases.assignTo')"
          v-if="
            checkRole([
              'head-audit-planner',
              'head-manager-for-audit-assignment',
              'head-technical-reviewer',
            ])
          "
        >
          <template #body="{ data, index }">
            <Dropdown
              v-if="checkRole(['head-audit-planner'])"
              v-model="data.auditPlannerId"
              :options="users"
              optionValue="id"
              :loading="loadSelection && loadSelectionIndex == index"
              @change="selectReviewer($event, data, index)"
              :class="screenWidth > breakPointValue ? 'w-17rem' : ''"
              showClear
            >
              <template #option="slotProps">
                {{ slotProps.option.username }} |
                {{ slotProps.option.firstName }} {{ slotProps.option.lastName }}
              </template>
              <template #value="slotProps">
                <div v-if="loadSelection && loadSelectionIndex == index">
                  {{ $t("cases.select") }}
                </div>
                <div
                  v-else-if="slotProps.value != null"
                  class="w-auto overflow-hidden text-overflow-ellipsis"
                >
                  {{ dropDownValue(slotProps.value) }}
                </div>
                <div v-else>
                  {{ $t("cases.select") }}
                </div>
              </template>
            </Dropdown>
            <Dropdown
              v-else-if="checkRole(['head-manager-for-audit-assignment'])"
              v-model="data.auditAssignerId"
              :options="users"
              optionValue="id"
              :placeholder="$t('cases.select')"
              :loading="loadSelection && loadSelectionIndex == index"
              @change="selectReviewer($event, data, index)"
              :class="screenWidth > breakPointValue ? 'w-17rem' : ''"
              showClear
            >
              <template #option="slotProps">
                {{ slotProps.option.username }} |
                {{ slotProps.option.firstName }} {{ slotProps.option.lastName }}
              </template>
              <template #value="slotProps">
                <div v-if="loadSelection && loadSelectionIndex == index">
                  {{ $t("cases.select") }}
                </div>
                <div
                  v-else-if="slotProps.value != null"
                  class="w-auto overflow-hidden text-overflow-ellipsis"
                >
                  {{ dropDownValue(slotProps.value) }}
                </div>
                <div v-else>
                  {{ $t("cases.select") }}
                </div>
              </template>
            </Dropdown>
            <Dropdown
              v-else-if="checkRole(['head-technical-reviewer'])"
              v-model="data.technicalReviewerId"
              :options="users"
              optionValue="id"
              :placeholder="$t('cases.select')"
              :loading="loadSelection && loadSelectionIndex == index"
              @change="selectReviewer($event, data, index)"
              :class="screenWidth > breakPointValue ? 'w-17rem' : ''"
              showClear
            >
              <template #option="slotProps">
                {{ slotProps.option.username }} |
                {{ slotProps.option.firstName }} {{ slotProps.option.lastName }}
              </template>
              <template #value="slotProps">
                <div v-if="loadSelection && loadSelectionIndex == index">
                  {{ $t("cases.select") }}
                </div>
                <div
                  v-else-if="slotProps.value != null"
                  class="w-auto overflow-hidden text-overflow-ellipsis"
                >
                  {{ dropDownValue(slotProps.value) }}
                </div>
                <div v-else>
                  {{ $t("cases.select") }}
                </div>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column
          field="Actions"
          :header="$t('global.actions')"
          :exportable="false"
          class="w-2 md:w-auto"
          v-if="
            !checkRole([
              'sales',
              'head-audit-planner',
              'audit-planner',
              'head-manager-for-audit-assignment',
            ])
          "
        >
          <template #body="{ data, index }">
            <div
              class="flex justify-content-evenly"
              :class="
                screenWidth < 1860 && screenWidth > breakPointValue
                  ? 'align-items-center flex-column'
                  : ''
              "
            >
              <router-link
                v-if="
                  checkRole(pagesPermissions.clients) &&
                  $route.name != 'clientInformation'
                "
                :to="`/clientInformation?userId=${data.application.userId}`"
                class="flex justify-content-center align-items-center"
              >
                <span class="mdi mdi-24px mdi-account-box"></span>
              </router-link>
              <Button
                v-if="
                  !auditCases && data.status == CaseStatuses.EVENT_CREATED.name
                "
                :label="$t('global.view')"
                class="p-button-success"
                @click="viewCase(data)"
              >
              </Button>
              <Button
                v-if="
                  checkRole([
                    'certification-manager',
                    'quality-assurance-manager',
                    'product-manager',
                  ])
                "
                :label="$t('cases.history')"
                class="p-button-success"
                @click="viewHistory(data)"
              >
              </Button>

              <!-- <Button
                v-else-if="
                  checkRole([
                    'audit-reviewer',
                    'head-technical-reviewer',
                    'technical-reviewer',
                  ]) && auditCases
                "
                :label="$t('global.view')"
                class="p-button-success"
                @click="viewFinishedAuditCase(data)"
              >
              </Button> -->
              <Button
                v-if="
                  checkRole([
                    'auditor',
                    'audit-reviewer',
                    'head-technical-reviewer',
                    'technical-reviewer',
                    'manager-for-certification-decision',
                  ]) ||
                  (checkRole(['client']) &&
                    data.status == CaseStatuses.TO_BE_ACCEPTED_BY_CLIENT.name &&
                    $route.name != 'invoices')
                "
                :label="$t('cases.audit')"
                class="p-button-success my-2"
                @click="viewAuditCase(data)"
              >
              </Button>
              <Button
                v-if="
                  checkRole(['manager-for-certification-decision']) &&
                  auditCases
                "
                :label="
                  data.status != CaseStatuses.CERTIFIED.name
                    ? $t('cases.issue')
                    : $t('global.view')
                "
                class="p-button-sm"
                :class="
                  data.status != CaseStatuses.CERTIFIED.name
                    ? 'p-button-success'
                    : 'p-button-warning'
                "
                @click="issueViewCertification(data, index)"
                iconPos="right"
                :loading="issueButtonLoading && indexLoading == index"
              >
              </Button>
              <Button
                v-if="
                  checkRole(['client']) &&
                  data.status == CaseStatuses.SCHEDULE_CREATED.name &&
                  $route.name != 'invoices'
                "
                :label="$t('global.view')"
                class="p-button-success"
                @click="
                  viewCase(
                    data,
                    data.status == 'audit event created' ? false : true
                  )
                "
              >
              </Button>
              <Button
                v-if="checkRole(['accounting'])"
                class="p-button-text p-0"
                @click="openInvoice(data)"
                :disabled="
                  checkRole(['accounting', 'client']) &&
                  data.invoiceStatus == 'pending'
                "
              >
                <span class="mdi mdi-24px mdi-eye"></span>
              </Button>

              <Button
                v-if="
                  checkRole(['accounting']) && data.invoiceStatus == 'issued'
                "
                class="p-button-success p-button-sm"
                :label="$t('cases.invoicePaid')"
                @click="issueInvoice(data, index)"
                iconPos="right"
                :loading="
                  issueInvoiceLoading && issueInvoiceLoadingIndex == index
                "
              >
              </Button>
              <Button
                v-else-if="
                  checkRole(['accounting']) && data.invoiceStatus == 'pending'
                "
                class="p-button-success p-button-sm"
                :label="$t('cases.addInvoice')"
                @click="viewAddInvoice(data)"
              >
              </Button>
              <Button
                v-else-if="
                  checkRole(['accounting', 'client']) &&
                  $route.name == 'invoices'
                "
                class="p-button-sm"
                :label="$t('cases.viewInvoice')"
                @click="openInvoice(data)"
              >
              </Button>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #content v-else-if="!loading">
      <NoTableData
        :type="
          $route.name == 'invoices' && checkRole(['client'])
            ? 'invoices'
            : 'cases'
        "
      ></NoTableData>
    </template>
  </Card>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { mapGetters, mapState } from "vuex";
// import ViewFinishedAuditCase from "./ViewFinishedAuditCase.vue";
import ViewCase from "./ViewCase.vue";
import AuditCase from "./ViewAuditCase.vue";
import CommentsFields from "../CommentsField.vue";
import AddInvoice from "./AddInvoice.vue";
import NoTableData from "../NoTableData.vue";
import CaseHistory from "./CaseHistory.vue";
import ViewInvoice from "./ViewInvoice.vue";
import ViewCertificate from "../Certifications/ViewCertificate.vue";

export default {
  name: "Cases table",
  props: ["auditCases", "dataUserId"],
  components: {
    // ViewFinishedAuditCase,
    ViewCase,
    AuditCase,
    CommentsFields,
    AddInvoice,
    NoTableData,
    CaseHistory,
    ViewInvoice,
    ViewCertificate,
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        companyName: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      loading: true,
      viewAudit: false,
      viewAuditData: {},
      openViewCase: false,
      viewCaseData: {},
      showAuditSections: false,
      issueButtonLoading: false,
      viewAuditFinished: false,
      viewAuditFinishedData: {},
      showAddInvoice: false,
      dataInvoice: {},
      loadSelection: false,
      loadSelectionIndex: null,
      viewCaseHistory: false,
      viewInvoice: false,
      invoiceCase: {},
      indexLoading: null,
      openViewCertificate: false,
      selectedCertificate: {},
      issueInvoiceLoading: false,
      issueInvoiceLoadingIndex: null,
      screenWidth: window.innerWidth,
      showDifferentAuditDate: false,
      blockedPage: false,
    };
  },
  computed: {
    ...mapGetters([
      "cases",
      "checkRole",
      "users",
      "translatedStatus",
      "certifications",
      "invoiceCases",
      "pagesPermissions",
      "isMobile",
    ]),
    ...mapState(["pagesPermissions", "CaseStatuses"]),
    breakPointValue() {
      let size = "1650";
      if (this.checkRole(["head-audit-planner"])) {
        size = "1648";
      } else if (this.checkRole(["accounting"])) {
        size = "1700";
      } else if (
        this.checkRole(["head-technical-reviewer", "technical-reviewer"])
      ) {
        size = "1719";
      } else if (this.checkRole(["manager-for-certification-decision"])) {
        size = "1530";
      }
      return size;
    },
    pageTitle() {
      let title = this.$t("cases.title");
      switch (this.$route.name) {
        case "invoices":
          title = this.$t("invoices.title");
          break;
        case "audits":
          title = this.$t("routes.audits");
          break;
      }
      return title;
    },
    dropDownValue() {
      return (id) => {
        let user = this.users.find((el) => el.id == id);

        if (user) {
          return `${user?.username} | ${user?.firstName} ${user?.lastName}`;
        }

        return this.$t("cases.select");
      };
    },
  },
  async mounted() {
    await this.getCases();
    if (this.checkRole(["auditor"])) {
      this.$action("audits-page-view");
    } else if (
      this.$route.name == "clientInformation" ||
      this.$route.name == "cases"
    ) {
      this.$action("cases-page-view");
    }
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
    if (this.checkRole(["head-audit-planner"])) {
      await this.$store.dispatch("userByRole", "audit-planner");
    } else if (this.checkRole(["head-manager-for-audit-assignment"])) {
      await this.$store.dispatch("userByRole", "manager-for-audit-assignment");
    } else if (this.checkRole(["head-technical-reviewer"])) {
      await this.$store.dispatch("userByRole", "technical-reviewer");
    }
    if (this.$route.query.caseId != "") {
      let caseObject = this.cases.find(
        (el) => el.id == this.$route.query.caseId
      );
      if (caseObject) {
        switch (caseObject.status) {
          case this.CaseStatuses.EVENT_CREATED.name:
            this.viewCase(caseObject);
            break;

          case this.CaseStatuses.SCHEDULE_CREATED.name:
            this.viewCase(caseObject, true);
            break;

          default:
            this.filters.id.value = parseInt(this.$route.query.caseId);
            break;
        }
      }
    }
  },
  methods: {
    async selectReviewer(event, data, index) {
      console.log(event, data);
      this.loadSelectionIndex = index;
      this.loadSelection = true;
      let updateAssignedObject = {
        id: data.id,
        version: data.version,
        auditAssignerId: data.auditAssignerId
          ? [
              Array.isArray(data.auditAssignerId)
                ? data.auditAssignerId[0]
                : data.auditAssignerId,
            ]
          : null,
        auditPlannerId: data.auditPlannerId
          ? [
              Array.isArray(data.auditPlannerId)
                ? data.auditPlannerId[0]
                : data.auditPlannerId,
            ]
          : null,
        technicalReviewerId: data.technicalReviewerId
          ? [
              Array.isArray(data.technicalReviewerId)
                ? data.technicalReviewerId[0]
                : data.technicalReviewerId,
            ]
          : null,
      };
      let user = this.users.find((el) => el.id == event.value);

      await this.$store
        .dispatch("assignToCase", updateAssignedObject)
        .then(() => {
          if (user) {
            if (this.checkRole(["head-audit-planner"])) {
              this.$action(
                "audit-planner-assign",
                {
                  targetUsername: user.username,
                  caseId: data.id,
                },
                "both"
              );
              this.$action(
                "case-get",
                {
                  username: user.username,
                  caseId: data.id,
                },
                "",
                user.username,
                user.id,
                "audit-planner"
              );
            } else if (this.checkRole(["head-manager-for-audit-assignment"])) {
              this.$action(
                "manager-for-audit-assignment-assign",
                {
                  targetUsername: user.username,
                  caseId: data.id,
                },
                "both"
              );

              this.$action(
                "case-get",
                {
                  username: user.username,
                  caseId: data.id,
                },
                "",
                user.username,
                user.id,
                "manager-for-audit-assignment"
              );
            } else if (this.checkRole(["head-technical-reviewer"])) {
              this.$action(
                "technical-reviewer-assign",
                {
                  targetUsername: user.username,
                  AuditId: data.schemeInstance.id,
                  caseId: data.id,
                },
                "auditCase"
              );

              this.$action(
                "case-get",
                {
                  username: user.username,
                  caseId: updateAssignedObject.id,
                },
                "",
                user.username,
                user.id,
                "technical-reviewer"
              );
            }
          }
        });
      this.loadSelection = false;
    },
    exportCSV() {
      this.$refs.tableCases.exportCSV();
      if (this.$route.name == "invoices") {
        this.$action("export-invoices");
      } else if (this.$route.name == "audits") {
        this.$action("export-audits");
      } else if (this.$route.name == "auditCases") {
        this.$action("export-audit-cases");
      } else {
        this.$action("export-cases");
      }
    },
    async getCases() {
      this.loading = true;
      let caseObject = {
        userId: "userId" in this.$route.query ? this.$route.query.userId : "",
      };
      await this.$store.dispatch("cases", caseObject);
      // if (this.checkRole(["admin"])) {
      //   await this.$store.dispatch("cases", {
      //     userId:
      //       "userId" in this.$route.query ? this.$route.query.userId : "",
      //   });
      // }
      // if (this.checkRole(["client"])) {
      //   await Promise.all([
      //     this.$store.dispatch("cases", {
      //       status: this.CaseStatuses.EVENT_CREATED.name,
      //     }),
      //     this.$store.dispatch("cases", {
      //       status: this.CaseStatuses.SCHEDULE_CREATED.name,
      //     }),
      //      this.$store.dispatch("cases", {
      //       status: this.CaseStatuses.TO_BE_ACCEPTED_BY_CLIENT.name,
      //     }),
      //   ]);
      // } else {
      //   if (this.checkRole(["audit-reviewer"])) {
      //     caseObject.status = this.CaseStatuses.TO_BE_REVIEWED_BY_REVIEWER.name;
      //     // await this.$store.dispatch("cases", "to be audited");
      //   } else if (this.checkRole(["auditor"])) {
      //     caseObject.status = this.CaseStatuses.TO_BE_AUDITED.name;
      //     // await this.$store.dispatch("cases", "audit event created");
      //   } else if (this.checkRole(["manager-for-certification-decision"])) {
      //     caseObject.status = this.CaseStatuses.TO_BE_CERTIFIED.name;
      //   } else if (this.checkRole(["accounting"])) {
      //     caseObject.status =
      //       this.CaseStatuses.SCHEDULE_ACCEPTED_BY_CLIENT.name;
      //     // await this.$store.dispatch("cases", "audit event created");
      //   } else if (this.checkRole(["head-audit-planner"])) {
      //     await this.$store.dispatch("userByRole", "audit-planner");
      //     caseObject.status = this.CaseStatuses.CREATE_PLAN.name;
      //   } else if (this.checkRole(["head-manager-for-audit-assignment"])) {
      //     await this.$store.dispatch(
      //       "userByRole",
      //       "manager-for-audit-assignment"
      //     );
      //     caseObject.status =
      //       this.CaseStatuses.TO_BE_ACCEPTED_BY_AUDIT_MANAGER.name;
      //   } else if (this.checkRole(["head-technical-reviewer"])) {
      //     await this.$store.dispatch("userByRole", "technical-reviewer");
      //     caseObject.status = this.CaseStatuses.TO_BE_REVIEWED_BY_REVIEWER.name;
      //   } else if (this.checkRole(["technical-reviewer"])) {
      //     caseObject.status = this.CaseStatuses.TO_BE_REVIEWED_BY_REVIEWER.name;
      //   }

      // }

      //  else if (
      //   this.checkRole([
      //     "certification-manager",
      //     "quality-assurance-manager",
      //     "product-manager",
      //   ])
      // ) {
      //   await this.$store.dispatch("cases", {});
      // }

      // if (this.checkRole(["head-audit-planner", "admin"])) {
      //   await this.$store.dispatch("userByRole", "audit-planner");
      //   await this.$store.dispatch("cases", {
      //     status: this.CaseStatuses.CREATE_PLAN.name,
      //   });
      // } else if (this.checkRole(["head-manager-for-audit-assignment"])) {
      //   await this.$store.dispatch(
      //     "userByRole",
      //     "manager-for-audit-assignment"
      //   );
      //   await this.$store.dispatch("cases", {
      //     status: this.CaseStatuses.CREATE_PLAN.name,
      //   });
      // } else if (this.checkRole(["head-technical-reviewer"])) {
      //   await this.$store.dispatch("userByRole", "technical-reviewer");
      //   await this.$store.dispatch("cases", {
      //     status: this.CaseStatuses.TO_BE_REVIEWED_BY_REVIEWER.name,
      //   });
      // }
      // if(this.auditCases) {
      // this.cases.forEach(async (el) => {
      //   console.log(el);
      //   // await this.$store.dispatch("certificationForCase", {
      //   //   caseId: el.id,
      //   //   certificationId: el.certification.id,
      //   // });
      //   // await this.$store.dispatch("applicationForCase", {
      //   //   caseId: el.id,
      //   //   applicationId: el.application.id,
      //   // });
      //   if (el.auditors) {
      //     el.auditors.forEach(async (el1) => {
      //       await this.$store.dispatch("userById", {
      //         caseId: el.id,
      //         userId: el1,
      //       });
      //     });
      //   }
      // });
      this.loading = false;
    },
    viewHistory() {
      this.viewCaseHistory = true;
    },
    closeAddRating() {
      this.openAddRating = false;
      this.addRatingCase = {}
    },
    closeCaseHistory() {
      this.viewCaseHistory = false;
    },
    viewFinishedAuditCase(data) {
      this.viewAuditFinished = true;
      this.viewAuditFinishedData = data;
    },
    closeFinishedViewAudit(load) {
      this.viewAuditFinished = false;
      this.viewAuditFinishedData = {};
      if (load) {
        this.getCases();
      }
    },
    openAuditCase(data) {
      this.viewAuditData = { ...data };
      this.viewAudit = true;
      if (this.checkRole(["head-technical-reviewer", "technical-reviewer"])) {
        this.$action("audit-completed-view", {
          reviewId: data.schemeInstance.id,
        });
      } else if (this.checkRole(["manager-for-certification-decision"])) {
        this.$action("audit-approved-view", {
          reviewId: data.schemeInstance.id,
        });
      } else if (this.checkRole(["auditor"])) {
        this.$store
          .dispatch("returnUserById", this.viewAuditData.application.userId)
          .then((res) => {
            this.$action("assigned-audits-page-view", {
              targetUsername: res.username,
            });
          });
      }
    },
    async viewAuditCase(data) {
      if (
        this.checkRole(["auditor"]) &&
        data.status != this.CaseStatuses.TO_BE_AMENDED.name
      ) {
        if (
          !this.$dayjs().isSame(
            this.$dayjs(data.auditDate, "DD/MM/YYYY"),
            "day"
          )
        ) {
          this.$confirm.require({
            message: this.$t("cases.auditDateCase").replace(
              "[date]",
              data.auditDate
            ),
            rejectClass: "hidden",
            header: `${this.$t("global.warning")}!`,
            rejectLabel: this.$t("global.close"),
            acceptLabel: this.$t("global.understood"),
          });
          return;
        }
        if (navigator.geolocation) {
          await navigator.geolocation.getCurrentPosition(async (location) => {
            try {
              this.blockedPage = true;
              await this.$store
                .dispatch("validateCoordinates", {
                  latitude: location.coords.latitude,
                  longitude: location.coords.longitude,
                  appId: data.application.id,
                })
                .then(() => {
                  this.blockedPage = false;
                  this.openAuditCase(data);
                });
            } catch {
              this.blockedPage = false;
              this.$confirm.require({
                message: this.$t("cases.wrongAuditorLocation"),
                header: `${this.$t("global.warning")}!`,
                rejectLabel: this.$t("global.close"),
                acceptLabel: this.$t("global.understood"),
              });
            }
          });
        } else {
          this.openAuditCase(data);
        }
      } else {
        this.openAuditCase(data);
      }
    },
    closeViewAudit(load) {
      this.viewAudit = false;
      this.viewAuditData = {};
      if (load) {
        this.getCases();
      }
    },
    viewCase(data, showAuditSections) {
      console.log(data);
      this.openViewCase = true;
      this.viewCaseData = data;
      this.showAuditSections = showAuditSections;
      if (this.checkRole(["client"])) {
        this.$action("case-view", { caseId: data.id });
      }
    },
    closeViewCase(load) {
      this.openViewCase = false;
      this.viewCaseData = {};
      this.showAuditSections = false;
      if (load) {
        this.getCases();
      }
    },
    async issueViewCertification(data, index) {
      this.issueButtonLoading = true;
      this.indexLoading = index;
      if (data.status == this.CaseStatuses.TO_BE_CERTIFIED.name) {
        await this.$store
          .dispatch("updateCaseStatus", {
            id: data.id,
            version: data.version,
            status: this.CaseStatuses.CERTIFIED.name,
          })
          .then((data) => {
            this.$action(
              "certificate-issue",
              {
                certificationId: data.certifiedId,
                caseId: data.id,
                applicationId: data.application.id,
              },
              "certificate"
            );
          });
        this.issueButtonLoading = false;
        this.indexLoading = null;
        this.$notification(
          this.$t("certifications.issueCertifitcation"),
          this.$t("certifications.certificationIssued")
        );
        this.getCases();
      } else if (data.status == this.CaseStatuses.CERTIFIED.name) {
        await this.$store.dispatch("certifications", {
          certId: data.certifiedId,
        });

        this.selectedCertificate = this.certifications;
        this.openViewCertificate = true;
        this.issueButtonLoading = false;
        this.indexLoading = null;
      }
    },
    viewAddInvoice(data) {
      this.showAddInvoice = true;
      this.dataInvoice = data;
    },
    closeViewCertificate() {
      this.openViewCertificate = false;
      this.selectedCertificate = {};
    },
    closeAddInvoice(load) {
      this.showAddInvoice = false;
      this.dataInvoice = {};
      if (load) {
        this.getCases();
      }
    },
    openInvoice(caseData) {
      this.viewInvoice = true;
      this.invoiceCase = caseData;
    },
    closeInvoice() {
      (this.viewInvoice = false), (this.invoiceCase = {});
    },
    async issueInvoice(data, index) {
      this.issueInvoiceLoading = true;
      this.issueInvoiceLoadingIndex = index;
      const tempData = { ...data };
      tempData.invoiceStatus = "paid";
      tempData.auditors = [];
      data.auditors.forEach((el) => {
        tempData.auditors.push(el.id);
      });
      tempData.auditDays =
        this.$dayjs(tempData.auditDateEnd, "DD/MM/YYYY").diff(
          this.$dayjs(tempData.auditDate, "DD/MM/YYYY"),
          "d"
        ) + 1;
      tempData.applicationId = tempData.application.id;
      await this.$store.dispatch("updateCase", tempData).then(() => {
        this.$action(
          "invoice-paid",
          { invoiceId: tempData.invoiceId, caseId: tempData.id },
          "both"
        );
      });
      this.issueInvoiceLoading = false;
      this.issueInvoiceLoadingIndex = null;
      this.getCases();
    },
    filterClear(field) {
      this.filters[field].value = null;
    },
    filterApply(field, data) {
      this.filters[field].value = data.value;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.noInvoice) {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
</style>
